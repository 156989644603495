'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Elements = require('./Elements');

var _Provider = require('./Provider');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// react-redux does a bunch of stuff with pure components / checking if it needs to re-render.
// not sure if we need to do the same.
var inject = function inject(WrappedComponent) {
  var _class, _temp;

  var componentOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _componentOptions$wit = componentOptions.withRef,
      withRef = _componentOptions$wit === undefined ? false : _componentOptions$wit;


  return _temp = _class = function (_React$Component) {
    _inherits(_class, _React$Component);

    function _class(props, context) {
      _classCallCheck(this, _class);

      if (!context || !context.getRegisteredElements) {
        throw new Error('It looks like you are trying to inject Stripe context outside of an Elements context.\nPlease be sure the component that calls createSource or createToken is within an <Elements> component.');
      }

      var _this = _possibleConstructorReturn(this, _React$Component.call(this, props, context));

      _this.findElement = function (specifiedType) {
        var allElements = _this.context.getRegisteredElements();
        var matchingElements = specifiedType === 'auto' ? allElements : allElements.filter(function (_ref) {
          var type = _ref.type;
          return type === specifiedType;
        });

        if (matchingElements.length === 1) {
          return matchingElements[0].element;
        } else if (matchingElements.length > 1) {
          throw new Error('You did not specify the type of Source or Token to create.\n        We could not infer which Element you want to use for this operation.');
        } else {
          return null;
        }
      };

      _this.requireElement = function (specifiedType) {
        var element = _this.findElement(specifiedType);
        if (element) {
          return element;
        } else {
          throw new Error('You did not specify the type of Source or Token to create.\n        We could not infer which Element you want to use for this operation.');
        }
      };

      _this.wrappedCreateToken = function (stripe) {
        return function () {
          var typeOrOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

          if (typeOrOptions && (typeof typeOrOptions === 'undefined' ? 'undefined' : _typeof(typeOrOptions)) === 'object') {
            var type = typeOrOptions.type,
                rest = _objectWithoutProperties(typeOrOptions, ['type']);

            var specifiedType = typeof type === 'string' ? type : 'auto';
            var element = _this.requireElement(specifiedType);
            return stripe.createToken(element, rest);
          } else if (typeof typeOrOptions === 'string') {
            return stripe.createToken(typeOrOptions, options);
          } else {
            throw new Error('Invalid options passed to createToken. Expected an object, got ' + (typeof typeOrOptions === 'undefined' ? 'undefined' : _typeof(typeOrOptions)) + '.');
          }
        };
      };

      _this.wrappedCreateSource = function (stripe) {
        return function () {
          var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          if (options && (typeof options === 'undefined' ? 'undefined' : _typeof(options)) === 'object') {
            var type = options.type,
                rest = _objectWithoutProperties(options, ['type']); // eslint-disable-line no-unused-vars


            var specifiedType = typeof type === 'string' ? type : 'auto';
            var element = _this.findElement(specifiedType);
            if (element) {
              return stripe.createSource(element, rest);
            } else if (specifiedType !== 'auto') {
              return stripe.createSource(options);
            } else {
              throw new Error('You did not specify the type of Source to create.\n          We also could not find any Elements in the current context.');
            }
          } else {
            throw new Error('Invalid options passed to createSource. Expected an object, got ' + (typeof options === 'undefined' ? 'undefined' : _typeof(options)) + '.');
          }
        };
      };

      if (_this.context.tag === 'sync') {
        _this.state = {
          stripe: _this.stripeProps(_this.context.stripe)
        };
      } else {
        _this.state = {
          stripe: null
        };
      }
      return _this;
    }

    _class.prototype.componentDidMount = function componentDidMount() {
      var _this2 = this;

      if (this.context.tag === 'async') {
        this.context.addStripeLoadListener(function (stripe) {
          _this2.setState({
            stripe: _this2.stripeProps(stripe)
          });
        });
      } else {
        // when 'sync', it's already set in the constructor.
      }
    };

    _class.prototype.getWrappedInstance = function getWrappedInstance() {
      if (!withRef) {
        throw new Error('To access the wrapped instance, the `{withRef: true}` option must be set when calling `injectStripe()`');
      }
      return this.wrappedInstance;
    };

    _class.prototype.stripeProps = function stripeProps(stripe) {
      return _extends({}, stripe, {
        // These are the only functions that take elements.
        createToken: this.wrappedCreateToken(stripe),
        createSource: this.wrappedCreateSource(stripe)
      });
    };
    // Finds the element by the specified type. Throws if multiple Elements match.

    // Require that exactly one Element is found.


    // createToken has a bit of an unusual method signature for legacy reasons
    // -- we're allowed to pass in the token type OR the element as the first parameter,
    // so we need to check if we're passing in a string as the first parameter and
    // just pass through the options if that's the case.


    _class.prototype.render = function render() {
      var _this3 = this;

      return _react2.default.createElement(WrappedComponent, _extends({}, this.props, {
        stripe: this.state.stripe,
        ref: withRef ? function (c) {
          _this3.wrappedInstance = c;
        } : null
      }));
    };

    return _class;
  }(_react2.default.Component), _class.contextTypes = _extends({}, _Provider.providerContextTypes, _Elements.injectContextTypes), _class.displayName = 'InjectStripe(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')', _temp;
};

exports.default = inject;