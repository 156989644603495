"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getMargin;
var toNumber = function toNumber(n) {
  return parseInt(n) || 0;
};

function getMargin(style) {
  return {
    top: toNumber(style.marginTop),
    right: toNumber(style.marginRight),
    bottom: toNumber(style.marginBottom),
    left: toNumber(style.marginLeft)
  };
}

module.exports = exports["default"];